import React from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const StartHero = () => {
  return (
    <React.Fragment>
      {/* Hero Style6 Warp Start */}
      <div className="hero-style6-warp">
        <div className="container-fluid style6">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="hero-style6-content">
                <h1 style={{ fontSize: "42px" }}>
                  Experience The Power Of Recharge POS: Recharge. Earn. Repeat.
                </h1>
                <p>
                  Instant Recharges, Utility Payments, and Commissions on Every
                  Transaction—All in One Place!
                </p>
                <div className="hero-store d-flex align-items-center">
                  <div className="play-store">
                    <a href="https://download.rechargepos.com/" target="_blank">
                      <img
                        src="assets/images/small-images/playstore.png"
                        alt="image"
                      />
                    </a>
                  </div>
                </div>
                <div className="user-content d-flex align-items-center">
                  {/* <ul className="user">
                      <li>
                        <img
                          src="assets/images/users/user1.png"
                          className="user1"
                          alt="user"
                        />
                      </li>
                      <li>
                        <img
                          src="assets/images/users/user2.png"
                          className="user1"
                          alt="user"
                        />
                      </li>
                      <li>
                        <img
                          src="assets/images/users/user3.png"
                          className="user1"
                          alt="user"
                        />
                      </li>
                      <li>
                        <img
                          src="assets/images/users/user4.png"
                          className="user1"
                          alt="user"
                        />
                      </li>
                    </ul> */}
                  <div className="content">
                    <h6>10K+</h6>
                    <span>User download our app</span>
                  </div>
                  <div className="review">
                    <h5>4.5</h5>
                    <div className="star">
                      <i className="ri-star-fill" />
                      <i className="ri-star-fill" />
                      <i className="ri-star-fill" />
                      <i className="ri-star-fill" />
                      <i className="ri-star-line" />
                      <p>Rating</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-style6-image">
                <img
                  src="/images/rechargepos-app.gif"
                  className="hero8"
                  alt="hero"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero Style6 Warp End */}
    </React.Fragment>
  );
};

export default StartHero;
